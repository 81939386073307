import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import Cwatch1 from "../../../images/uploads/cwatch/cwatch1.png"
import Cwatch2 from "../../../images/uploads/cwatch/cwatch2.png"
import Cwatch3 from "../../../images/uploads/cwatch/cwatch3.png"
import Cwatch5 from "../../../images/uploads/cwatch/cwatch5.png"
import Cwatch6 from "../../../images/uploads/cwatch/cwatch6.png"
import Cwatch7light from "../../../images/uploads/cwatch/cwaatch7light.png"
import Cwatch7dark from "../../../images/uploads/cwatch/cwaatch7dark.png"
import Cwatchm1 from "../../../images/uploads/cwatch/cwatchm1.png"
import Cwatchm2 from "../../../images/uploads/cwatch/cwatchm2.png"
import Cwatchm3 from "../../../images/uploads/cwatch/cwatchm3.png"
import Cwatchm4 from "../../../images/uploads/cwatch/cwatchm4.png"
import Cwatchm5 from "../../../images/uploads/cwatch/cwatchm5.png"
import Cwatchm6 from "../../../images/uploads/cwatch/cwatchm6.png"
import Cwatchm7 from "../../../images/uploads/cwatch/cwatchm7.png"
import Cwatchm8 from "../../../images/uploads/cwatch/cwatchm8.png"

import addcs1 from "../../../images/uploads/decodeup-smart-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-callaed-additional-case-study.png"
const Cwatch = props => {

  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang

  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "cwatch"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />


        {/* banner  section */}
        <section className="h-full xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-3-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Security Company
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Truck Driver's Security App
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  You’ve heard it before, but we have to repeat: you need a
                  website, regardless of your business' size or industry. The
                  client came with concerns regarding the atrocity in black
                  communities in the country, and an idea to safeguard the
                  community against this police violence and brutality.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Marcus F.
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      USA
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, SEO, Maintenance and Support, UI/UX Design,
                      Product management, Hosting
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Android
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Security
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cwat mx-auto">
            <img
              className=" mx-auto"
              src={Cwatch1}
              alt="C-watch Decodeup Technologies"
            />
          </div>
        </section>

        {/* Banner intro section */}
        <section className="relative mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cwat mx-auto">
            <div className="flex flex-wrap mms:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center mms:items-start lg:items-center">
              <div className="order-2 mms:order-1 w-full mms:w-2/5">
                <img
                  className="mx-auto"
                  src={Cwatch2}
                  alt="C-watch Decodeup Technologies"
                />
              </div>

              <div className="order-1 mms:order-2 w-full mms:w-3/5">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Value Safety with Community in Your Pocket
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  As we know, unity is strength, you just have to be a platform
                  to unite people. So the client decided to build a mobile app
                  to unite the community when one of its members is in need.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The app also features live streaming where users can show if
                  any civilian is facing brutality in the area and share that
                  video with their contact list.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The simple idea was to alert nearby members with a single
                  notification that let them know someone near them is in
                  trouble, with use of live location users can come to each
                  other's support or rescue.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Banner intro section */}
        <section className="relative mb-60 md:mb-20 xl:mb-1-40 c-intro-bg">
          <div className="container container-cwat mx-auto">
            <div className="">
              <div className="md:pl-10 lg:pl-20 mb-20 w-full md:w-2/3 lg:w-1/2">
                <h3 class="pr-10 mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Unite the community in a timely way through digital media
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The medium in which users can alert nearby community members
                  with just a single click and other members get notified that
                  someone in the area needs your help.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  By using the location, users can see the live location and
                  route to the oppressed member. That person will also know who
                  is coming to support or rescue them and their live location
                  with the route.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  When users are released by law enforcement officials or make
                  it home, they can alert their most frequent contact and/or
                  friend.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Users can go live to show how the law officer is serving the
                  member, and all members can view the live streaming.
                </p>
              </div>
              <div className="w-full text-center">
                <img
                  className="mx-auto"
                  src={Cwatch3}
                  alt="Contemporary Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* intro bold */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cwat mx-auto">
            <div className="mt-16 md:mt-20 mx-auto w-full max-w-9-76 text-center">
              <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary ">
                This app was very important to the community so we took the
                responsibility seriously to help solve a dangerous issue. We
                knew that this app demanded a lot of our team so we wasted no
                time detailing the pros and cons of each feature and how to
                address them.
              </h3>
            </div>
          </div>
        </section>

        {/* challenges section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cwat mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-8 lg:gap-16 xl:items-center">
              <div className="w-full lg:w-2/5 hidden md:block max-w-480 ">
                <img
                  className="mx-auto w-full h-full object-contain object-top c-light"
                  src={Cwatch5}
                  alt="C-watch Decodeup Technologies"
                />
                <img
                  className="mx-auto w-full h-full object-contain object-top c-dark"
                  src={Cwatch6}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
              <div className="w-full lg:w-3/5">
                <h2 className="mb-3 lg:mb-6-6 pr-10 font-poppings-bold text-rh2 lg:text-h2 xl:text-h1 text-term-primary">
                  The Challenges
                </h2>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  As the client discussed the initial roadmap of the app,
                  DecodeUp's team started basic feature development of the main
                  module and idea that is to connect the people with their
                  locations.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  By the time development was in progress and the client had
                  more ideas to expand the platform for community support. Our
                  team needed to connect the main module with expansion and find
                  the best way to fulfil the requirement of the platform growth.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We also had to create a user interface so users could ask for
                  help or alert members in no time with a single tap. Every
                  major functionality must be available and there is also the
                  need to enable live streaming that requires appropriate
                  infrastructure for top user experience.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The app's main feature is to track detainees and must be able
                  to track the person who has been tortured by police when the
                  detainee clicks the alert button. The app tracks route between
                  detainee, contact members and favorite people who are within 5
                  kilometers.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  App should have the feature of live streaming where users can
                  share live video of the detainee who is abused by law officers
                  or any unusual activity in the nearby area.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  To create a better user experience similar to social media
                  platforms where users send and receive friend requests to
                  connect with friends, designate close circle and share files
                  between friends. It also features chat service with friends
                  and the ability to call someone in your contact list.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  To maintain privacy, users can share profile details with
                  everyone or just friends and share details of the law official
                  with their law office identity information, video and pitchers
                  with members or just friends.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* solutions */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cwat mx-auto">
            <div className="w-full mb-6 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left">
                Solutions
              </h2>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-12">
              <div className="w-full sm:w-1/2 md:w-1/3">
                <div className=" flex-cnt mb-2 md:mb-4 lg:mb-8 w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-full flex items-center justify-center">
                  <span className="text-rp1 lg:text-h3 font-poppings-bold text-term-primary">
                    1
                  </span>
                </div>
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  DecodeUp team created user-friendly mobile app
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The team's efforts led to an application on which users can
                  register and create profiles with desired privacy settings.
                  The app also allows users to manage their data safely and
                  securely.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We created a mobile application so users to reach out for help
                  whenever they have trouble with law enforcement.
                </p>
              </div>

              <div className="w-full sm:w-1/2 md:w-1/3">
                <div className=" flex-cnt mb-2 md:mb-4 lg:mb-8 w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-full flex items-center justify-center">
                  <span className="text-rp1 lg:text-h3 font-poppings-bold text-term-primary">
                    2
                  </span>
                </div>
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Simple flow with every major feature at a single tap
                </h3>

                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  With just a tap users can alert members in the community for
                  help and track the live location of the helper or oppressed
                  person. The tracking also features an alert button with route
                  to the surrounding area within a 5 kilometer range. We created
                  a feature so users can see detainee profile details for more
                  info on how to assist.
                </p>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3">
                <div className=" flex-cnt mb-2 md:mb-4 lg:mb-8 w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-full flex items-center justify-center">
                  <span className="text-rp1 lg:text-h3 font-poppings-bold text-term-primary">
                    3
                  </span>
                </div>
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Multiple features, complete functionality.
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We created an app like social media. Where you can send friend
                  requests r and become friends and can add members to close
                  circles and favorite lists.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We enabled a live streaming module with the necessary
                  infrastructure to provide support and air regarding law office
                  incidents. You can share your identity with that law office.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We have created a file sharing module where users can upload
                  images or videos from their camera and can share it with other
                  members.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Type Face */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cwat mx-auto">
            <div className="mb-10 w-full ">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                Typeface & Colors
              </h2>
            </div>
            <div className="mb-8 lg:mb-12 flex flex-wrap md:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center justify-between">
              <div className="w-full md:w-1/4 max-w-480">
                <img className="c-light" src={Cwatch7light} />
                <img className="c-dark" src={Cwatch7dark} />
              </div>
              <div className="w-full mms:w-5/20 md:w-2/4 cwatch-typo text-term-primary">
                <h4 className="mb-3 lg:mb-8 text-term-primary">Montserrat</h4>
                <h5 className="mb-3 lg:mb-3 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h6 className="text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h6>
              </div>
              <div className="mt-8 mms:mt-0 w-full mms:w-5/48 md:w-2/4 flex mms:justify-end items-center text-term-primary">
                <div className="flex justify-center items-center rounded-full w-2-72 h-2-72 cwatch-color-1">
                  <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                    #03CE5D
                  </p>
                </div>
                <div className="mms:-ml-4 lg:-ml-8 xl:-ml-16 relative z-1 flex justify-center items-center rounded-full w-2-72 h-2-72 cwatch-color-2">
                  <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                    #222222
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cwat mx-auto">
            <div className="md:w-7/10">
              <h2 className="mb-4 lg:mb-6-6 pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                The team at DecodeUp created a structured mobile application
                featuring a stellar user experience and all the necessary
                features to unite the community
              </p>

              <div className="mt-6 w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  We were happy to build this app for the community and look
                  forward to do the same for you.
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* slider sec */}
        <section className="pt-8 lg:pt-20 mb-60 md:mb-20 xl:mb-1-40 c-greenbg">
          <div className="container container-cwat mx-auto">
            <div className="flex flex-wrap">
              <div className="w-full mms:w-1/2 md:w-1/3 lg:w-1/4">
                <img
                  className="w-full h-full object-cover"
                  src={Cwatchm1}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
              <div className="w-full mms:w-1/2 md:w-1/3 lg:w-1/4">
                <img
                  className="w-full h-full object-cover"
                  src={Cwatchm2}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
              <div className="w-full mms:w-1/2 md:w-1/3 lg:w-1/4">
                <img
                  className="w-full h-full object-cover"
                  src={Cwatchm3}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
              <div className="w-full mms:w-1/2 md:w-1/3 lg:w-1/4">
                <img
                  className="w-full h-full object-cover"
                  src={Cwatchm4}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
              <div className="w-full mms:w-1/2 md:w-1/3 lg:w-1/4">
                <img
                  className="w-full h-full object-cover"
                  src={Cwatchm5}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
              <div className="w-full mms:w-1/2 md:w-1/3 lg:w-1/4">
                <img
                  className="w-full h-full object-cover"
                  src={Cwatchm6}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
              <div className="w-full mms:w-1/2 md:w-1/3 lg:w-1/4">
                <img
                  className="w-full h-full object-cover"
                  src={Cwatchm7}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
              <div className="w-full mms:w-1/2 md:w-1/3 lg:w-1/4">
                <img
                  className="w-full h-full object-cover"
                  src={Cwatchm8}
                  alt="C-watch Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>
        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cwat mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/smart-notes-and-puzzles-platform"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Smart Notes & Puzzles Platform
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs1}
                      alt="Smart Notes Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default Cwatch

export const pageQuery = graphql`
query cwatchpagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/truck-drivers-security-app.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;